import React from 'react'
import {graphql, useStaticQuery} from "gatsby"
import "../styles/home-features.scss"

const HomeFeatures = () => {
    const data = useStaticQuery(graphql`
        query Features {
            allWpMereResource(
                sort: {fields: date, order: DESC}
                limit: 8
                filter: {status: {eq: "publish"}, resourceTypes: {nodes: {elemMatch: {slug: {ne: "books"}}}}}
            ) {
                nodes {
                    title
                    featuredImage {
                        node {
                            mediaDetails {
                                sizes {
                                    sourceUrl
                                    name
                                }
                            }
                        }
                    }
                    uri
                }
            }
        }
    `)

    return (

        <section className="home-features container">
            <h3>The Latest</h3>
            <div className="home-features__grid">
                {data.allWpMereResource.nodes.map((card, index) =>
                    <a key={index} href={card.uri} className="home-features__grid__card">
                        {card.featuredImage && card.featuredImage.node.mediaDetails && card.featuredImage.node.mediaDetails.sizes ? (
                            <div className="home-features__grid__card__image"
                                 style={{backgroundImage: `url("${card.featuredImage.node.mediaDetails.sizes.find(size => size.name === 'medium')?.sourceUrl}")`}}/>
                        ) : ''}
                        <span className="home-features__grid__card__title">{card.title}</span>
                    </a>
                )}
            </div>
        </section>
    )
}

export default HomeFeatures