import React from "react"
import {graphql, useStaticQuery} from 'gatsby'
import Hero from "../components/hero"
import HomeFeatures from "../components/HomeFeatures"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const heroStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(https://bibletolife.wpengine.com/wp-content/uploads/2021/10/sixteen-miles-out-PpJOb2ByPBY-unsplash.webp)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}
const HomePage = () => {

    const data = useStaticQuery(graphql`
        query PopularQuery {
            allWpTopic(filter: {popularity: {popular: {eq: true}}}) {
                nodes {
                    name
                    uri
                }
            }
        }
    `)

    return (
        <Layout isIndex={true}>
            <Seo
                title="Home"
                description="Get to know the Bible."
            />
            <Hero style={heroStyle} data={data}/>
            <HomeFeatures/>
        
        </Layout>
    )
}

export default HomePage


